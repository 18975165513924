var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"certificate__sidebar"},[_c('div',{staticClass:"sidebar"},[_c('div',{staticClass:"sidebar__text-settings"},[(
          !_vm.disabledClass &&
          _vm.tutorial.showTutorial &&
          !_vm.tutorial.createTemplate &&
          !_vm.tutorial.addImage &&
          !_vm.tutorial.changeImage &&
          _vm.tutorial.addText
        )?_c('OnboardingCard',{attrs:{"tutorialStep":"skip"},on:{"tutorial":_vm.handleTutorial},scopedSlots:_vm._u([{key:"title",fn:function(){return [_vm._v(_vm._s(_vm.$t('label.textField')))]},proxy:true},{key:"label",fn:function(){return [_vm._v(_vm._s(_vm.$t('supportText.selectTextBlock')))]},proxy:true}],null,false,1117401848)}):_vm._e(),_c('div',{staticClass:"sidebar__title"},[_c('p',[_vm._v(_vm._s(_vm.$t('editorjs.text')))])]),_c('div',{staticClass:"sidebar__subtitle"},[_c('p',[_vm._v(_vm._s(_vm.$t('supportText.pressButtonToAdd')))])]),_c('div',{staticClass:"sidebar__text-buttons cursor-pointer"},_vm._l((_vm.textButtons),function(textButton){return _c('AddButton',{key:textButton.name,class:{ selected: _vm.notActive(textButton.name), 'soft-disabled': _vm.disabledClass },attrs:{"title":textButton.text,"variant":"secondary","size":"small"},on:{"click":function($event){return _vm.drawText(textButton)}}})}),1)],1),_c('div',{staticClass:"sidebar__text-style-settings"},[(
          !_vm.disabledClass &&
          _vm.tutorial.showTutorial &&
          !_vm.tutorial.createTemplate &&
          !_vm.tutorial.addImage &&
          !_vm.tutorial.changeImage &&
          !_vm.tutorial.addText &&
          _vm.tutorial.changeStyle
        )?_c('OnboardingCard',{attrs:{"tutorialStep":"skip"},on:{"tutorial":_vm.handleTutorial},scopedSlots:_vm._u([{key:"title",fn:function(){return [_vm._v(_vm._s(_vm.$t('label.textStyle')))]},proxy:true},{key:"label",fn:function(){return [_vm._v(_vm._s(_vm.$t('supportText.changeTextStyleAndColor')))]},proxy:true}],null,false,2088409611)}):_vm._e(),_c('CertificateTextSetting',{attrs:{"text":_vm.selectedText,"disabled":_vm.disabledClass,"setDefaultTextSetting":_vm.setDefaultTextSetting,"handleTutorial":_vm.handleTutorial,"tutorial":_vm.tutorial}})],1),_c('div',{staticClass:"sidebar__text-alignment text-alignment"},[_c('div',{staticClass:"sidebar__title"},[_c('p',[_vm._v(_vm._s(_vm.$t('label.alignment')))])]),_c('div',{staticClass:"text-alignment__icons"},[_c('CenterVerticalAlignmentIcon',{on:{"click":function($event){return _vm.align('center-v')}}}),_c('CenterHorizontalAlignmentIcon',{on:{"click":function($event){return _vm.align('center-h')}}})],1)]),_c('div',{staticClass:"sidebar__image-settings"},[_c('div',{staticClass:"sidebar__title"},[_c('p',[_vm._v(_vm._s(_vm.$t('label.uploadedImage')))])]),(_vm.formData || _vm.imgName)?_c('div',{staticClass:"sidebar__image-link",on:{"click":_vm.openImage}},[_vm._v(" "+_vm._s(_vm.imageName)+" "),_c('b-icon-upload')],1):_vm._e(),(
          !_vm.disabledClass &&
          _vm.tutorial.showTutorial &&
          !_vm.tutorial.createTemplate &&
          !_vm.tutorial.addImage &&
          _vm.tutorial.changeImage
        )?_c('OnboardingCard',{attrs:{"tutorialStep":"skip"},on:{"tutorial":_vm.handleTutorial},scopedSlots:_vm._u([{key:"title",fn:function(){return [_vm._v(_vm._s(_vm.$t('label.uploadedImage')))]},proxy:true},{key:"label",fn:function(){return [_vm._v(_vm._s(_vm.$t('supportText.youCanChangeImage')))]},proxy:true}],null,false,1526684880)}):_vm._e()],1),_c('div',{staticClass:"certificate__save-button"},[_c('AddButton',{attrs:{"title":_vm.$t('buttonLabels.save'),"variant":"primary","isDisabled":_vm.loadingSave || _vm.disabledClass},on:{"click":_vm.save}}),(!_vm.disabledClass)?_c('p',{staticClass:"certificate__remove-link",on:{"click":_vm.showModalRemove}},[_vm._v(" "+_vm._s(_vm.$t('label.removeCertificate'))+" ")]):_vm._e()],1)])])
}
var staticRenderFns = []

export { render, staticRenderFns }