<template>
  <div class="certificate__sidebar">
    <div class="sidebar">
      <div class="sidebar__text-settings">
        <OnboardingCard
          v-if="
            !disabledClass &&
            tutorial.showTutorial &&
            !tutorial.createTemplate &&
            !tutorial.addImage &&
            !tutorial.changeImage &&
            tutorial.addText
          "
          @tutorial="handleTutorial"
          tutorialStep="skip"
        >
          <template #title>{{ $t('label.textField') }}</template>
          <template #label>{{ $t('supportText.selectTextBlock') }}</template>
        </OnboardingCard>
        <div class="sidebar__title">
          <p>{{ $t('editorjs.text') }}</p>
        </div>
        <div class="sidebar__subtitle">
          <p>{{ $t('supportText.pressButtonToAdd') }}</p>
        </div>
        <div class="sidebar__text-buttons cursor-pointer">
          <AddButton
            v-for="textButton in textButtons"
            :key="textButton.name"
            :title="textButton.text"
            variant="secondary"
            size="small"
            :class="{ selected: notActive(textButton.name), 'soft-disabled': disabledClass }"
            @click="drawText(textButton)"
          />
        </div>
      </div>
      <div class="sidebar__text-style-settings">
        <OnboardingCard
          v-if="
            !disabledClass &&
            tutorial.showTutorial &&
            !tutorial.createTemplate &&
            !tutorial.addImage &&
            !tutorial.changeImage &&
            !tutorial.addText &&
            tutorial.changeStyle
          "
          @tutorial="handleTutorial"
          tutorialStep="skip"
        >
          <template #title>{{ $t('label.textStyle') }}</template>
          <template #label>{{ $t('supportText.changeTextStyleAndColor') }}</template>
        </OnboardingCard>
        <CertificateTextSetting
          :text="selectedText"
          :disabled="disabledClass"
          :setDefaultTextSetting="setDefaultTextSetting"
          :handleTutorial="handleTutorial"
          :tutorial="tutorial"
        />
      </div>
      <div class="sidebar__text-alignment text-alignment">
        <div class="sidebar__title">
          <p>{{ $t('label.alignment') }}</p>
        </div>
        <div class="text-alignment__icons">
          <CenterVerticalAlignmentIcon @click="align('center-v')" />
          <CenterHorizontalAlignmentIcon @click="align('center-h')" />
        </div>
      </div>
      <div class="sidebar__image-settings">
        <div class="sidebar__title">
          <p>{{ $t('label.uploadedImage') }}</p>
        </div>
        <div v-if="formData || imgName" class="sidebar__image-link" @click="openImage">
          {{ imageName }}
          <b-icon-upload />
        </div>
        <OnboardingCard
          v-if="
            !disabledClass &&
            tutorial.showTutorial &&
            !tutorial.createTemplate &&
            !tutorial.addImage &&
            tutorial.changeImage
          "
          @tutorial="handleTutorial"
          tutorialStep="skip"
        >
          <template #title>{{ $t('label.uploadedImage') }}</template>
          <template #label>{{ $t('supportText.youCanChangeImage') }}</template>
        </OnboardingCard>
      </div>
      <div class="certificate__save-button">
        <AddButton
          :title="$t('buttonLabels.save')"
          variant="primary"
          :isDisabled="loadingSave || disabledClass"
          @click="save"
        />
        <p
          class="certificate__remove-link"
          @click="showModalRemove"
          v-if="!disabledClass">
          {{$t('label.removeCertificate')}}
        </p>
      </div>
    </div>
  </div>
</template>

<script>
import CertificateTextSetting from '@/components/Teacher/Certificate/CertificateTextSetting.vue';
import CenterHorizontalAlignmentIcon from '@/components/Icons/Alignment/CenterHorizontalAlignmentIcon.vue';
import CenterVerticalAlignmentIcon from '@/components/Icons/Alignment/CenterVerticalAlignmentIcon.vue';
import OnboardingCard from '@/components/Cards/OnboardingCard.vue';

export default {
  name: 'SidebarCertificate',
  components: {
    AddButton: () => import('@/components/Buttons/AddButton.vue'),
    CertificateTextSetting,
    CenterHorizontalAlignmentIcon,
    CenterVerticalAlignmentIcon,
    OnboardingCard,
  },
  props: {
    textButtons: {
      type: Array,
      required: true,
    },
    notActive: {
      type: Function,
      required: true,
    },
    openImage: {
      type: Function,
      required: true,
    },
    disabledClass: {
      type: Boolean,
      required: true,
    },
    loadingSave: {
      type: Boolean,
      required: true,
    },
    drawText: {
      type: Function,
      required: true,
    },
    align: {
      type: Function,
      required: true,
    },
    handleTutorial: {
      type: Function,
      required: true,
    },
    save: {
      type: Function,
      required: true,
    },
    removeCertificate: {
      type: Function,
      required: true,
    },
    setDefaultTextSetting: {
      type: Function,
      required: true,
    },
    selectedText: {
      type: Object,
      default: () => ({}),
    },
    tutorial: {
      type: Object,
      required: true,
    },
    formData: {
      type: FormData,
      default: () => ({}),
    },
    imgName: {
      type: String,
      default: '',
    },
    imageName: {
      type: String,
      default: '',
    },
  },
  methods: {
    showModalRemove() {
      this.$bvModal
        .msgBoxConfirm(
          this.$t('supportText.actionCannotBeUndoneCertificate'),
          {
            size: 'sm',
            okVariant: 'secondary',
            cancelVariant: 'primary',
            headerClass: 'p-2 border-bottom-0',
            footerClass: 'lms-modal__footer border-top-0',
            centered: true,
            bodyClass: 'lms-modal__body',
            okTitle: this.$t('buttonLabels.delete'),
            cancelTitle: this.$t('buttonLabels.cancel'),
          },
        )
        .then((isConfirmed) => (isConfirmed))
        .then(this.checkModalConfirm)
        .catch(console.log);
    },
    checkModalConfirm(isConfirmed) {
      if (!isConfirmed) {
        return;
      }
      this.removeCertificate();
    },
  },
};
</script>
