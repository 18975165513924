<template>
  <div class="certificate" ref="diploma">
    <div class="certificate__canvas-container" :class="canvasClass">
      <div class="back-button">
        <BackButton :label="backButtonLabel" :routeToPush="backRoute" :key="backRoute" />
      </div>

      <transition @afterEnter="afterEnter" name="component-fade" mode="out-in">
        <div v-if="loading" key="loading" class="certificate__loading f-center h-100">
          <div>
            <Loader />
          </div>
        </div>
        <div v-else-if="certificateError" key="error" class="certificate__loading f-center h-100">
        <div class='text-primary'>
          {{$t("errorMessages.somethingWentWrong")}}
          <span
            class="text-underline cursor-pointer"
            @click="reload">
            {{$t("supportText.tryRefreshPage")}}
          </span>
        </div>
      </div>
        <div v-else-if="!hasData" ref="empty" key="empty" class="certificate__empty-state">
          <transition name="component-fade" mode="out-in">
            <OnboardingCard
              v-if="tutorial.showTutorial && tutorial.createTemplate"
              variant="big"
              @tutorial="handleTutorial"
              tutorialStep="createTemplate"
            >
              <template #title>{{ $t('supportText.createCertificateTemplate') }}</template>
              <template #text> {{ $t('supportText.createAndConfigCert') }}</template>
            </OnboardingCard>
            <div v-else class="empty-state-wrapper">
              <div class="empty-state">
                <div class="empty-state__title">
                  <p>{{ $t('header.certificateTemplate') }}</p>
                </div>
                <div class="empty-state__subtitle">
                  <p>{{ $t('supportText.toStartUploadImage') }}</p>
                </div>
                <div class="empty-state__action">
                  <AddButton
                    @click="() => this.$refs.load.click()"
                    :title="$t('buttonLabels.uploadFile')"
                    variant="primary"
                    size="small"
                  />
                </div>
              </div>
              <OnboardingCard
                v-if="tutorial.showTutorial && tutorial.addImage"
                @tutorial="handleTutorial"
                tutorialStep="skip"
              >
                <template #title>{{ $t('header.Image') }}</template>
                <template #label>{{ $t('supportText.addImageAsBackground') }}</template>
              </OnboardingCard>
            </div>
          </transition>
        </div>
        <div v-else key="data" ref="canvas-wrapper" class="certificate__canvas">
          <div ref="canvas" :class="{ 'f-center': scale < 1 }"></div>
        </div>
      </transition>

      <div v-if="!loading && hasData" class="certificate__zoom">
        <div class="certificate-zoom">
          <ZoomOut @click="zoomOut" />
          <div class="text-white mx-2 w-100 text-center user-select-none">
            {{ Math.round(scale * 100) }}%
          </div>
          <ZoomIn @click="zoomIn" />
        </div>
      </div>
    </div>

    <SidebarCertificate
      :textButtons="textButtons"
      :notActive="notActive"
      :disabledClass="disabledClass"
      :drawText="drawText"
      :selectedText="selectedText"
      :align="align"
      :loadingSave="loadingSave"
      :formData="formData"
      :save="save"
      :imgName="imgName"
      :imageName="imageName"
      :openImage="openImage"
      :setDefaultTextSetting="setDefaultTextSetting"
      :tutorial="tutorial"
      :handleTutorial="handleTutorial"
      :removeCertificate="removeCertificate"
    />

    <input
      type="file"
      id="imageLoader"
      class="d-none"
      @change="upload"
      ref="load"
      :accept="acceptedTypes"
    />
  </div>
</template>

<script>
import Konva from 'konva';
import WebFont from 'webfontloader';
import cloneDeep from 'lodash/cloneDeep';
import { mapActions, mapGetters } from 'vuex';
import Loader from '@/components/Loader/Loader.vue';
import BackButton from '@/components/Buttons/BackButton.vue';
import ZoomIn from '@/components/Icons/ZoomIn.vue';
import ZoomOut from '@/components/Icons/ZoomOut.vue';
import MaterialService from '@/services/material.service';
// eslint-disable-next-line import/no-cycle
import ActivityService from '@/services/activity.service';
import { abc, certificateTutorial } from '@/utils/constants';
import SidebarCertificate from '@/components/Teacher/Certificate/SidebarCertificate.vue';
import OnboardingCard from '@/components/Cards/OnboardingCard.vue';

export default {
  name: 'CertificateConstructor',
  components: {
    OnboardingCard,
    SidebarCertificate,
    ZoomOut,
    ZoomIn,
    AddButton: () => import('@/components/Buttons/AddButton.vue'),
    Loader,
    BackButton,
  },
  data: () => ({
    canvasClass: '',

    loading: true,
    loadingSave: false,
    hasData: false,
    imageUrl: null,
    imgName: null,
    konvaSettings: null,
    editMode: false,

    formData: null,
    fileImage: null,
    pdfToImageUrl: null,
    activityName: null,
    max_score: null,

    canvas: null,
    stage: null,
    layer: null,
    tr: null,
    selectedText: null,
    stageContainer: null,
    addedText: null,

    guideLineOffset: 5,

    scale: 1,
    scaleBy: 1.1,

    fileSizeLimit: 100 * 1024 * 1024,
    whiteListExtensions: ['png', 'jpeg', 'jpg', 'pdf'],

    textSetting: {
      align: 'left',
      fontFamily: 'Roboto',
      fontStyle: 'normal',
      fontSize: 16,
      fill: '#000000',
    },

    defaultTextSetting: {
      align: 'left',
      fontFamily: 'Roboto',
      fontStyle: 'normal',
      fontSize: 16,
      fill: '#000000',
    },

    tutorial: {
      showTutorial: true,
      createTemplate: true,
      addImage: true,
      changeImage: true,
      addText: true,
      changeStyle: true,
    },

    MIN_WIDTH: 30,
    certificateError: false,
  }),

  computed: {
    ...mapGetters({ user: 'auth/getUser' }),
    backRoute() {
      const { courseId, programId } = this.$route.params;
      if (+courseId === 0) {
        return `programs/${programId}`;
      }
      return `course/${programId}/${courseId}`;
    },
    backButtonLabel() {
      const { courseId } = this.$route.params;
      if (+courseId === 0) {
        return this.$t('label.backToProgram');
      }
      return this.$t('buttonLabels.backToCourse');
    },

    textButtons() {
      return [
        {
          name: 'fio',
          text: this.$t('label.fio'),
        },
        {
          name: 'score',
          text: this.$t('label.points'),
        },
        {
          name: 'name',
          text: this.$t('editorjs.title'),
        },
        {
          name: 'optionalText',
          text: this.$t('editorjs.optionalText'),
        },
      ];
    },
    imageName() {
      if (this.imgName) {
        return this.imgName;
      }
      return this.fileImage?.name || 'Загрузить файл';
    },
    textNodes() {
      return this.layer?.children.filter((n) => !!n.textHeight) || [];
    },
    notActive() {
      return (dataName) => {
        const index = this.textNodes.findIndex(
          (el) => (el.attrs.name === dataName && (el.attrs.visible || el.attrs.visible !== false)),
        );
        return index > -1;
      };
    },

    disabledClass() {
      return !(this.formData || this.imgName);
    },
    acceptedTypes() {
      return this.whiteListExtensions.map((ext) => `.${ext}`).join(', ');
    },
  },

  async created() {
    const tutorial = localStorage.getItem(certificateTutorial);
    if (tutorial) {
      this.tutorial = JSON.parse(tutorial);
    }
    // eslint-disable-next-line max-len
    const activityId = +this.$route.params.courseId !== 0 ? this.$route.params.courseId : this.$route.params.programId;
    await ActivityService.getActivityById(activityId)
      .then(({ data }) => {
        this.activityName = data.name;
        this.max_score = data.max_score;
      })
      .catch(console.log);
  },

  async mounted() {
    try {
      const result = await this.getDataFromServer();
      if (result) {
        result.children[0].children.forEach((ch) => {
          if (ch.className === 'Image') {
            this.imageUrl = `https://${ch.imageUrl}`;
            this.imgName = ch.imgName;
          }
        });
        this.konvaSettings = result;
        this.hasData = true;
      }
    } catch (e) {
      this.certificateError = true;
    } finally {
      this.loading = false;
    }
  },

  methods: {
    ...mapActions({ setToaster: 'toaster/setToaster' }),

    // after transition from empty state to canvas id finished - init Konva
    async afterEnter(el) {
      if (el.className.includes('certificate__canvas')) {
        this.canvas = this.$refs.canvas;

        if (!this.stage) {
          await this.initKonva();
        }

        if (this.konvaSettings) {
          this.drawImageFromUrl();
        } else {
          this.drawImage();
          await new Promise((resolve) => setTimeout(resolve, 200));
          for (let i = 0; i < this.textButtons.length; i += 1) {
            this.drawText(this.textButtons[i]);
            // eslint-disable-next-line no-await-in-loop
            await new Promise((resolve) => setTimeout(resolve, 200));
          }
        }
      }
    },
    async getDataFromServer() {
      const id = +this.$route.params.courseId === 0
        ? this.$route.params.programId
        : this.$route.params.courseId;

      return new Promise((resolve, reject) => {
        ActivityService.getCertificate(id).then((response) => {
          this.editMode = true;
          resolve(response.data.config);
        }).catch((error) => {
          if (error.response?.status === 404) {
            resolve(null);
          } else {
            reject();
          }
        });
      });
    },

    async initKonva() {
      if (this.konvaSettings) {
        await this.initKonvaWithSettings();
      } else {
        const { width, height } = this.canvas.getBoundingClientRect();
        this.stage = new Konva.Stage({
          container: this.canvas,
          width,
          height,
        });
        this.layer = new Konva.Layer();
        this.stage.add(this.layer);
      }

      this.tr = new Konva.Transformer({
        rotateEnabled: false,
        padding: 5,
        borderStroke: '#FAA433',
        anchorStroke: '#FAA433',
        keepRatio: false,
        enabledAnchors: [
          'middle-left',
          'middle-right',
          'top-left',
          'top-right',
          'bottom-left',
          'bottom-right'],
        anchorSize: 5,
        // limit transformer size
        boundBoxFunc: (oldBox, newBox) => {
          const { height } = this.canvas.getBoundingClientRect();
          // check if newBox fit canvas height at decreasing width
          if (
            Math.abs(newBox.height) > Math.abs(height - 30)
            && oldBox.width > newBox.width
          ) {
            return oldBox;
          }
          // eslint-disable-next-line no-param-reassign
          newBox.width = Math.max(30, newBox.width > 600 ? 600 : newBox.width);
          return newBox;
        },
        anchorDragBoundFunc: (oldPos, newPos) => {
          if (this.tr.getActiveAnchor() === 'top-left'
          || this.tr.getActiveAnchor() === 'top-right'
          || this.tr.getActiveAnchor() === 'bottom-right'
          || this.tr.getActiveAnchor() === 'bottom-left') {
            return {
              x: newPos.x,
              y: oldPos.y,
            };
          }
          return newPos;
        },
      });

      this.layer.add(this.tr);

      // clicks should select/deselect shapes
      this.stage.on('click tap', (e) => {
        if (e.target.attrs.name?.includes('anchor')) {
          return;
        }
        // remove all selections
        if (
          !e.target.parent
          || e.target.attrs.name === 'image'
        ) {
          this.tr.nodes([]);
          this.selectedText = null;
          return;
        }

        // do we pressed shift or ctrl?
        const metaPressed = e.evt.shiftKey || e.evt.ctrlKey || e.evt.metaKey;
        const isSelected = this.tr.nodes().indexOf(e.target) >= 0;

        if (!metaPressed && !isSelected) {
          // if no key pressed and the node is not selected
          // select just one
          this.tr.nodes([e.target]);
          this.selectedText = e.target;
        } else if (metaPressed && isSelected) {
          // if we pressed keys and node was selected
          // we need to remove it from selection:
          const nodes = this.tr.nodes().slice(); // use slice to have new copy of array
          // remove node from array
          nodes.splice(nodes.indexOf(e.target), 1);
          this.selectedText = null;
          this.tr.nodes(nodes);
        } else if (metaPressed && !isSelected) {
          // add the node into selection
          const nodes = this.tr.nodes().concat([e.target]);
          this.tr.nodes(nodes);
          this.selectedText = e.target;
        }
      });

      // this.layer.on('dragmove', this.layerDragMove);
      // this.layer.on('dragend', this.layerDragEnd);

      this.stageContainer = this.stage.container();
      this.stageContainer.tabIndex = 1;
      this.stageContainer.addEventListener('keydown', this.keyDownDelete);
    },
    async initKonvaWithSettings() {
      const fontsFamilies = this.konvaSettings.children[0].children.reduce((acc, curr) => {
        if (curr.className === 'Text') {
          acc.push(curr.attrs.fontFamily);
        }
        return acc;
      }, []);
      if (fontsFamilies.length) {
        try {
          await this.loadFont(fontsFamilies);
        } catch (e) {
          console.log(e);
        }
      }

      this.konvaSettings.children[0].children
        .forEach((item) => {
          if (item.className !== 'Image') {
            this.textButtons.forEach((button) => {
              if (
                item.className === 'Text'
                  && item.attrs.name === button.name
                  && button.name !== 'optionalText'
              ) {
                // eslint-disable-next-line no-param-reassign
                item.attrs.text = this.textMock(button);
              }
            });
          }
        });

      const konvaSettingsText = this.konvaSettings.children[0].children
        .filter((item) => item.className !== 'Image' && item.className !== 'Transformer');
      const konvaSettigsWithoutImage = cloneDeep(this.konvaSettings);
      konvaSettigsWithoutImage.children[0].children = konvaSettingsText;

      this.stage = Konva.Node.create(konvaSettigsWithoutImage, this.$refs.canvas);

      // eslint-disable-next-line prefer-destructuring
      this.layer = this.stage.children[0];

      const { width } = this.canvas.getBoundingClientRect();

      this.layer.children.map((node) => {
        if (node.attrs.text) {
          this.addTextListener(node);
          if (node.textWidth > width) {
            node.width(width * 0.9);
          }
        }
        return null;
      });
    },

    openImage() {
      this.$refs.load.click();
      this.handleTutorial('changeImage');
    },
    async upload(event) {
      const file = this.$refs.load.files[0];
      if (file) {
        if (!this.validateFileExtension(file)) {
          return;
        }
        if (!this.validateFileSize(file)) {
          return;
        }

        this.formData = new FormData();
        const files = Array.from(event.target.files);
        // eslint-disable-next-line prefer-destructuring
        this.fileImage = files[0];
        const spiltName = file.name.split('.');
        const flExt = spiltName[spiltName.length - 1];
        if (flExt === 'pdf') {
          await this.handlePdfFile(file);
        } else {
          this.formData.append('file', file);
          this.pdfToImageUrl = null;
        }

        this.imgName = null;
        this.hasData = true;

        if (this.stage) {
          this.drawImage();
        }

        this.handleTutorial('addImage');
      }
    },
    handlePdfFile(file) {
      // TODO add reject ana handle it
      return new Promise((resolve) => {
        const pdfjsLib = require('pdfjs-dist/es5/build/pdf.min');

        if ('Worker' in window) {
          // eslint-disable-next-line import/no-webpack-loader-syntax
          const PdfjsWorker = require('worker-loader!pdfjs-dist/es5/build/pdf.worker').default;
          pdfjsLib.GlobalWorkerOptions.workerPort = new PdfjsWorker();
        }

        const reader = new FileReader();
        reader.onload = () => {
          const typedArray = new Uint8Array(reader.result);
          const loadingTask = pdfjsLib.getDocument(typedArray);
          loadingTask.promise.then((pdf) => {
            pdf.getPage(1).then(async (page) => {
              await this._convertPagePdfToPng(page);
              resolve();
            });
          });
        };
        reader.readAsArrayBuffer(file);
      });
    },
    _dataURItoBlob(dataURI) {
      // convert base64/URLEncoded data component to raw binary data held in a string
      let byteString;
      if (dataURI.split(',')[0].indexOf('base64') >= 0) byteString = atob(dataURI.split(',')[1]);
      else byteString = unescape(dataURI.split(',')[1]);

      // separate out the mime component
      const mimeString = dataURI.split(',')[0].split(':')[1].split(';')[0];

      // write the bytes of the string to a typed array
      const ia = new Uint8Array(byteString.length);
      // eslint-disable-next-line no-plusplus
      for (let i = 0; i < byteString.length; i++) {
        ia[i] = byteString.charCodeAt(i);
      }

      return new Blob([ia], { type: mimeString });
    },
    async _convertPagePdfToPng(page) {
      const canvas = document.createElement('canvas');
      canvas.className = 'd-none';
      const scale = 1.5;
      const viewport = page.getViewport({ scale });
      canvas.height = viewport.height;
      canvas.width = viewport.width;

      const renderContext = {
        canvasContext: canvas.getContext('2d'),
        viewport,
      };
      const result = page.render(renderContext);
      await result.promise;
      this.$refs.diploma.appendChild(canvas);
      this.pdfToImageUrl = canvas.toDataURL();
      const blob = await this._dataURItoBlob(this.pdfToImageUrl);
      const blobToFile = new File([blob], 'image.png', {
        type: blob.type,
        lastModified: Date.now(),
      });

      this.formData.append('file', blobToFile);
      canvas.remove();
    },

    drawImage() {
      this.resetScale();

      const imageObj = new Image();
      imageObj.onload = () => {
        const isVerticalImage = imageObj.width < imageObj.height;
        this.canvasClass = isVerticalImage ? 'certificate--vertical' : '';
        this.canvas.style.width = `${isVerticalImage ? 600 : 900}px`;
        this.canvas.style.height = `${isVerticalImage ? 800 : 640}px`;

        this.stage.width(isVerticalImage ? 600 : 900);
        this.stage.height(isVerticalImage ? 800 : 640);

        const hRatio = this.stage.width() / imageObj.width;
        const vRatio = this.stage.height() / imageObj.height;
        const ratio = Math.min(hRatio, vRatio);

        const centerShift_x = (this.stage.width() - imageObj.width * ratio) / 2;
        const centerShift_y = (this.stage.height() - imageObj.height * ratio) / 2;

        URL.revokeObjectURL(imageObj.src);
        const image = new Konva.Image({
          x: centerShift_x,
          y: centerShift_y,
          image: imageObj,
          width: imageObj.width * ratio,
          height: imageObj.height * ratio,
          name: 'image',
        });

        // reset previous image
        if (this.imageNode) {
          this.imageNode.remove();
          this.imageNode = null;
        }
        this.layer.add(image);
        image.zIndex(0);

        this.imageNode = image;
      };
      imageObj.src = this.pdfToImageUrl ? this.pdfToImageUrl : URL.createObjectURL(this.fileImage);
    },
    drawImageFromUrl() {
      Konva.Image.fromURL(this.imageUrl, (image) => {
        const isVerticalImage = image.width() < image.height();
        this.canvasClass = isVerticalImage ? 'certificate--vertical' : '';
        this.canvas.style.width = `${isVerticalImage ? 600 : 900}px`;
        this.canvas.style.height = `${isVerticalImage ? 800 : 640}px`;

        this.stage.width(isVerticalImage ? 600 : 900);
        this.stage.height(isVerticalImage ? 800 : 640);

        const hRatio = this.stage.width() / image.width();
        const vRatio = this.stage.height() / image.height();
        const ratio = Math.min(hRatio, vRatio);

        const centerShift_x = (this.stage.width() - image.width() * ratio) / 2;
        const centerShift_y = (this.stage.height() - image.height() * ratio) / 2;

        image.x(centerShift_x);
        image.y(centerShift_y);
        image.width(image.width() * ratio);
        image.height(image.height() * ratio);
        image.name('image');

        this.layer.add(image);
        this.layer.draw();
        this.imageNode = image;
        image.zIndex(0);
      });
    },
    async drawText(textButton) {
      if (!this.stage) {
        return;
      }
      if (this.textNodes.find((n) => n.attrs.name === textButton.name)) {
        if (this.selectedText?.attrs.name === textButton.name) {
          return;
        }
        const node = this.textNodes.find((n) => n.attrs.name === textButton.name);

        // node.fontStyle(this.textSetting.fontStyle);
        // node.fontSize(this.textSetting.fontSize);
        // node.fill(this.textSetting.fill);

        node.show();
        this.selectTextByButton(textButton);
        // this.loadFont([this.textSetting.fontFamily]).then(async () => {
        //   // node.fontFamily(this.textSetting.fontFamily);
        //   await new Promise((resolve) => setTimeout(() => {
        //     resolve();
        //   }, 300));
        //   node.show();
        //   this.selectTextByButton(textButton);
        // });
        if (!this.tutorial.changeImage) {
          this.handleTutorial('addText');
        }
        return;
      }

      const { width, height } = this.canvas.getBoundingClientRect();
      const text = new Konva.Text({
        x: width / 2,
        y: height / 2,
        align: this.textSetting.align,
        text: this.textMock(textButton),
        fill: this.textSetting.fill,
        draggable: true,
        name: textButton.name,
      });

      this.loadFont([this.textSetting.fontFamily]).then(() => {
        text.fontFamily(this.textSetting.fontFamily);
        text.fontStyle(this.textSetting.fontStyle);
        text.fontSize(this.textSetting.fontSize);
        // resize if text overflow by width
        if (text.textWidth > width) {
          text.width(width * 0.9);
        } else if (text.textWidth < 30) {
          // if near to 0, set min width
          text.width(30);
        } else {
          // change width a litle to specify it in konva as custom width
          text.width(text.textWidth + 1);
        }
        // align center by default
        text.x((width / 2) - (text.textWidth / 2));
        text.hide();
        this.addTextListener(text);
        this.layer.add(text);
      });
    },
    textMock(button) {
      if (button.name === 'fio') {
        return `${this.user.first_name} ${this.user.last_name}`;
      }
      if (button.name === 'score') {
        return `${this.max_score} / ${this.max_score}`;
      }
      if (button.name === 'name') {
        return this.activityName;
      }
      return button.text;
    },

    selectTextByButton(textButton) {
      const textNode = this.textNodes.find((n) => n.attrs.name === textButton.name);
      this.tr.nodes([textNode]);
      this.selectedText = textNode;
      this.stageContainer.focus();
    },
    setDefaultTextSetting(value) {
      const key = Object.keys(value)[0];
      if (value[key]) {
        this.textSetting[key] = value[key];
      }
    },
    resetTextStyle(node) {
      node.fontFamily(this.defaultTextSetting.fontFamily);
      node.fontStyle(this.defaultTextSetting.fontStyle);
      node.fontSize(this.defaultTextSetting.fontSize);
      const { width, height } = this.canvas.getBoundingClientRect();
      node.absolutePosition({ x: width / 2, y: height / 2 });
    },

    align(side) {
      // TODO - with zoom
      if (!this.selectedText) {
        return;
      }
      if (side === 'center-h') {
        return this.selectedText.y((this.stage.height() / 2) - (this.selectedText.textHeight / 2));
      }
      if (side === 'center-v') {
        return this.selectedText.x((this.stage.width() / 2) - (this.selectedText.textWidth / 2));
      }
      if (side === 'left') {
        return this.selectedText.x(50);
      }
      if (side === 'right') {
        return this.selectedText.x(this.stage.width() - 50 - this.selectedText.width());
      }
    },

    addTextListener(node) {
      node.on('transform', () => {
        // with enabled anchors we can only change scaleX
        // so we don't need to reset height
        // just width
        node.setAttrs({
          width: Math.max(node.width() * node.scaleX(), 30),
          scaleX: 1,
          scaleY: 1,
        });
      });
      // does not allow to move element out of stage
      node.on('dragmove', () => {
        if (
          node.y()
          > this.stage.height() - this.$refs['canvas-wrapper'].scrollTop - node.height()
        ) {
          node.y(this.stage.height() - this.$refs['canvas-wrapper'].scrollTop - node.height());
        } else {
          node.y(Math.max(node.y(), 0));
        }

        if (
          node.x()
          > this.stage.width() - this.$refs['canvas-wrapper'].scrollLeft - node.width()
        ) {
          node.x(this.stage.width() - this.$refs['canvas-wrapper'].scrollLeft - node.width());
        } else {
          node.x(Math.max(node.x(), 0));
        }
      });
      this.editTextNode(node);
    },
    editTextNode(textNode) {
      textNode.on('dblclick dbltap', () => {
        // hide text node and transformer:
        textNode.hide();
        this.tr.hide();

        // create textarea over canvas with absolute position
        // first we need to find position for textarea
        // how to find it?

        // at first lets find position of text node relative to the stage:
        const textPosition = textNode.absolutePosition();
        const app = document.getElementById('app');

        // so position of textarea will be the sum of positions above:
        const areaPosition = {
          x:
            this.stage.container().offsetLeft
            - this.$refs['canvas-wrapper'].scrollLeft
            + textPosition.x,
          y:
            this.stage.container().offsetTop
            - this.$refs['canvas-wrapper'].scrollTop
            - app.scrollTop
            + textPosition.y
            - 1,
        };

        // create textarea and style it
        const textarea = document.createElement('textarea');
        document.body.appendChild(textarea);

        // apply many styles to match text on canvas as close as possible
        // remember that text rendering on canvas and on the textarea can be different
        // and sometimes it is hard to make it 100% the same. But we will try...
        textarea.value = textNode.text();
        textarea.style.position = 'absolute';
        textarea.style.top = `${areaPosition.y}px`;
        textarea.style.left = `${areaPosition.x}px`;
        textarea.style.width = `${textNode.width() - textNode.padding() * 2}px`;
        textarea.style.height = `${textNode.height() - textNode.padding() * 2 + 5}px`;
        textarea.style.fontSize = `${textNode.fontSize()}px`;
        textarea.style.border = 'none';
        textarea.style.padding = '0px';
        textarea.style.margin = '0px';
        textarea.style.overflow = 'hidden';
        textarea.style.background = 'none';
        textarea.style.outline = 'none';
        textarea.style.resize = 'none';
        textarea.style.lineHeight = textNode.lineHeight();
        textarea.style.fontFamily = textNode.fontFamily();
        textarea.style.fontStyle = textNode.fontStyle().split(' ').length === 2
          ? textNode.fontStyle().split(' ')[0]
          : textNode.fontStyle;
        textarea.style.fontWeight = textNode.fontStyle().split(' ').length === 2 ? textNode.fontStyle().split(' ')[1] : '';
        textarea.style.transformOrigin = 'left top';
        textarea.style.textAlign = textNode.align();
        textarea.style.color = textNode.fill();
        const rotation = textNode.rotation();
        let transform = '';
        if (rotation) {
          transform += `rotateZ(${rotation}deg)`;
        }

        let px = 0;
        // also we need to slightly move textarea on firefox
        // because it jumps a bit
        const isFirefox = navigator.userAgent.toLowerCase().indexOf('firefox') > -1;
        if (isFirefox) {
          px += 2 + Math.round(textNode.fontSize() / 20);
        }
        transform += `translateY(-${px}px)`;

        textarea.style.transform = transform;

        // reset height
        textarea.style.height = 'auto';
        // after browsers resized it we can set actual value
        textarea.style.height = `${textarea.scrollHeight + 3}px`;

        textarea.focus();
        const self = this;

        function removeTextarea() {
          textarea.parentNode.removeChild(textarea);
          // eslint-disable-next-line no-use-before-define
          window.removeEventListener('click', handleOutsideClick);
          // eslint-disable-next-line no-use-before-define
          self.$refs['canvas-wrapper'].removeEventListener('scroll', removeOnScroll);
          textNode.show();
          self.tr.show();
          self.tr.forceUpdate();
        }

        function setTextareaWidth(newWidth) {
          if (!newWidth) {
            // set width for placeholder
            // eslint-disable-next-line no-param-reassign
            newWidth = textNode.placeholder.length * textNode.fontSize();
          }
          // some extra fixes on different browsers
          const isSafari = /^((?!chrome|android).)*safari/i.test(navigator.userAgent);
          const isFF = navigator.userAgent.toLowerCase().indexOf('firefox') > -1;
          if (isSafari || isFF) {
            // eslint-disable-next-line no-param-reassign
            newWidth = Math.ceil(newWidth);
          }

          const isEdge = document.documentMode || /Edge/.test(navigator.userAgent);
          if (isEdge) {
            // eslint-disable-next-line no-param-reassign
            newWidth += 1;
          }
          textarea.style.width = `${newWidth}px`;
        }

        textarea.addEventListener('keydown', (e) => {
          // hide on enter
          // but don't hide on shift + enter
          if (e.keyCode === 13 && !e.shiftKey) {
            textNode.text(textarea.value);
            removeTextarea();
          }
          // on esc do not set value back to node
          if (e.keyCode === 27) {
            removeTextarea();
          }
        });

        // eslint-disable-next-line no-unused-vars
        textarea.addEventListener('keydown', (_) => {
          const scale = textNode.getAbsoluteScale().x;
          setTextareaWidth(textNode.width() * scale);
          textarea.style.height = 'auto';
          textarea.style.height = `${textarea.scrollHeight + textNode.fontSize()}px`;
        });

        function handleOutsideClick(e) {
          if (e.target !== textarea) {
            textNode.text(textarea.value);
            removeTextarea();
          }
        }

        function removeOnScroll() {
          textNode.text(textarea.value);
          removeTextarea();
        }

        setTimeout(() => {
          window.addEventListener('click', handleOutsideClick);
          this.$refs['canvas-wrapper'].addEventListener('scroll', removeOnScroll);
        });
      });
    },
    keyDownDelete(e) {
      // remove text on delete
      if (e.code === 'Delete' && this.selectedText) {
        this.selectedText.hide();
        this.tr.nodes([]);
        this.selectedText = null;
      }
      e.preventDefault();
    },
    loadFont(families) {
      return new Promise((resolve) => {
        WebFont.load({
          google: {
            families,
            text: abc,
          },
          active: resolve,
        });
      });
    },

    zoomIn() {
      this.scale = this.stage.scaleX();

      const oldScale = this.stage.scaleX();
      const newScale = oldScale * this.scaleBy;

      this.stage.scale({ x: newScale, y: newScale });
      this.scale = this.stage.scaleX();

      this.stage.width(this.stage.width() * this.scaleBy);
      this.stage.height(this.stage.height() * this.scaleBy);

      this.scrollCanvas();
    },
    zoomOut() {
      this.scale = this.stage.scaleX();

      const oldScale = this.stage.scaleX();
      const newScale = oldScale / this.scaleBy;

      this.stage.scale({ x: newScale, y: newScale });
      this.scale = this.stage.scaleX();

      this.stage.width(this.stage.width() / this.scaleBy);
      this.stage.height(this.stage.height() / this.scaleBy);

      this.scrollCanvas();
    },
    scrollCanvas() {
      if (this.scale === this.scaleBy) {
        this.$refs['canvas-wrapper'].scrollLeft = this.stage.width() / 20;
        this.$refs['canvas-wrapper'].scrollTop = this.stage.height() / 20;
      }
    },
    resetScale() {
      this.stage.scale({ x: 1, y: 1 });
      this.scale = this.stage.scaleX();
    },

    // ===== Next methods for showing guide lines =====
    // were can we snap our objects?
    getLineGuideStops(skipShape) {
      // we can snap to stage borders and the center of the stage
      const vertical = [0, this.stage.width() / 2, this.stage.width()];
      const horizontal = [0, this.stage.height() / 2, this.stage.height()];

      // and we snap over edges and center of each object on the canvas
      this.textButtons.map((el) => {
        this.stage.find(`.${el.name}`).forEach((guideItem) => {
          if (guideItem === skipShape) {
            return;
          }
          const box = guideItem.getClientRect();
          // and we can snap to all edges of shapes
          vertical.push([box.x, box.x + box.width, box.x + box.width / 2]);
          horizontal.push([box.y, box.y + box.height, box.y + box.height / 2]);
        });
        return el;
      });
      return {
        vertical: vertical.flat(),
        horizontal: horizontal.flat(),
      };
    },
    // what points of the object will trigger to snapping?
    // it can be just center of the object
    // but we will enable all edges and center
    getObjectSnappingEdges(node) {
      const box = node.getClientRect();
      const absPos = node.absolutePosition();

      return {
        vertical: [
          {
            guide: Math.round(box.x),
            offset: Math.round(absPos.x - box.x),
            snap: 'start',
          },
          {
            guide: Math.round(box.x + box.width / 2),
            offset: Math.round(absPos.x - box.x - box.width / 2),
            snap: 'center',
          },
          {
            guide: Math.round(box.x + box.width),
            offset: Math.round(absPos.x - box.x - box.width),
            snap: 'end',
          },
        ],
        horizontal: [
          {
            guide: Math.round(box.y),
            offset: Math.round(absPos.y - box.y),
            snap: 'start',
          },
          {
            guide: Math.round(box.y + box.height / 2),
            offset: Math.round(absPos.y - box.y - box.height / 2),
            snap: 'center',
          },
          {
            guide: Math.round(box.y + box.height),
            offset: Math.round(absPos.y - box.y - box.height),
            snap: 'end',
          },
        ],
      };
    },
    // find all snapping possibilities
    getGuides(lineGuideStops, itemBounds) {
      const resultV = [];
      const resultH = [];

      lineGuideStops.vertical.forEach((lineGuide) => {
        itemBounds.vertical.forEach((itemBound) => {
          const diff = Math.abs(lineGuide - itemBound.guide);
          // eslint-disable-next-line max-len
          // if the distance between guild line and object snap point is close we can consider this for snapping
          if (diff < this.guideLineOffset) {
            resultV.push({
              lineGuide,
              diff,
              snap: itemBound.snap,
              offset: itemBound.offset,
            });
          }
        });
      });

      lineGuideStops.horizontal.forEach((lineGuide) => {
        itemBounds.horizontal.forEach((itemBound) => {
          const diff = Math.abs(lineGuide - itemBound.guide);
          if (diff < this.guideLineOffset) {
            resultH.push({
              lineGuide,
              diff,
              snap: itemBound.snap,
              offset: itemBound.offset,
            });
          }
        });
      });

      const guides = [];

      // find closest snap
      const minV = resultV.sort((a, b) => a.diff - b.diff)[0];
      const minH = resultH.sort((a, b) => a.diff - b.diff)[0];
      if (minV) {
        guides.push({
          lineGuide: minV.lineGuide,
          offset: minV.offset,
          orientation: 'V',
          snap: minV.snap,
        });
      }
      if (minH) {
        guides.push({
          lineGuide: minH.lineGuide,
          offset: minH.offset,
          orientation: 'H',
          snap: minH.snap,
        });
      }
      return guides;
    },
    drawGuides(guides) {
      guides.forEach((lg) => {
        let line;
        if (lg.orientation === 'H') {
          line = new Konva.Line({
            points: [-6000, 0, 6000, 0],
            stroke: '#FAA433',
            strokeWidth: 1,
            name: 'guid-line',
            dash: [4, 6],
          });
          this.layer.add(line);
          line.absolutePosition({
            x: 0,
            y: lg.lineGuide,
          });
        } else if (lg.orientation === 'V') {
          line = new Konva.Line({
            points: [0, -6000, 0, 6000],
            stroke: '#FAA433',
            strokeWidth: 1,
            name: 'guid-line',
            dash: [4, 6],
          });
          this.layer.add(line);
          line.absolutePosition({
            x: lg.lineGuide,
            y: 0,
          });
        }
      });
    },

    layerDragMove(e) {
      // clear all previous lines on the screen
      this.layer.find('.guid-line').forEach((l) => l.destroy());

      // find possible snapping lines
      const lineGuideStops = this.getLineGuideStops(e.target);
      // find snapping points of current object
      const itemBounds = this.getObjectSnappingEdges(e.target);

      // now find where can we snap current object
      const guides = this.getGuides(lineGuideStops, itemBounds);

      // do nothing of no snapping
      if (!guides.length) {
        return;
      }

      this.drawGuides(guides);

      const absPos = e.target.absolutePosition();
      // now force object position
      guides.forEach((lg) => {
        // eslint-disable-next-line default-case
        switch (lg.snap) {
          case 'start': {
            // eslint-disable-next-line default-case
            switch (lg.orientation) {
              case 'V': {
                absPos.x = lg.lineGuide + lg.offset;
                break;
              }
              case 'H': {
                absPos.y = lg.lineGuide + lg.offset;
                break;
              }
            }
            break;
          }
          case 'center': {
            // eslint-disable-next-line default-case
            switch (lg.orientation) {
              case 'V': {
                absPos.x = lg.lineGuide + lg.offset;
                break;
              }
              case 'H': {
                absPos.y = lg.lineGuide + lg.offset;
                break;
              }
            }
            break;
          }
          case 'end': {
            // eslint-disable-next-line default-case
            switch (lg.orientation) {
              case 'V': {
                absPos.x = lg.lineGuide + lg.offset;
                break;
              }
              case 'H': {
                absPos.y = lg.lineGuide + lg.offset;
                break;
              }
            }
            break;
          }
        }
      });
      e.target.absolutePosition(absPos);
    },
    layerDragEnd() {
      this.layer.find('.guid-line').forEach((l) => l.destroy());
    },
    // ===== END of methods for showing guide lines =====

    validateFileExtension(file) {
      const spiltName = file.name.split('.');
      const flExt = spiltName[spiltName.length - 1];
      if (!this.whiteListExtensions.includes(flExt)) {
        const toast = {
          title: this.$t('errorMessages.fileNotSupported'),
          body: `${this.$t('supportText.onlyOfTheFormat')} ${this.acceptedTypes}`,
        };
        this.setToaster({
          type: 'toast-danger',
          toast,
        });
        return false;
      }
      return true;
    },
    validateFileSize(file) {
      if (this.fileSizeLimit < file?.size) {
        const toast = {
          title: this.$t('errorMessages.sizeToLarge'),
          body: `${this.$t('supportText.maximumFileSize')} - ${this.formatBytes(
            this.fileSizeLimit,
          )}`,
        };
        this.setToaster({
          type: 'toast-danger',
          toast,
        });
        return false;
      }
      return true;
    },
    formatBytes(a, b = 2) {
      if (a === 0) return '0 Bytes';
      const c = b < 0 ? 0 : b;
      const d = Math.floor(Math.log(a) / Math.log(1024));
      return `${parseFloat((a / 1024 ** d).toFixed(c))} ${
        ['Bytes', 'KB', 'MB', 'GB', 'TB', 'PB', 'EB', 'ZB', 'YB'][d]
      }`;
    },

    async save() {
      if (this.disabledClass) {
        return;
      }
      const oldScale = this.stage.scaleX();
      this.stage.scale({ x: 1, y: 1 });

      const obj = this.stage.toObject();

      this.stage.scale({ x: oldScale, y: oldScale });

      this.loadingSave = true;
      const url = await this.saveImageToServer();
      obj.children[0].children.forEach((ch) => {
        if (ch.className === 'Image') {
          if (url) {
            // eslint-disable-next-line no-param-reassign
            ch.imageUrl = url;
            // eslint-disable-next-line no-param-reassign
            ch.imgName = this.fileImage?.name;
          } else {
            const parsed = this.konvaSettings;
            parsed.children[0].children.forEach((child) => {
              if (child.className === 'Image') {
                // eslint-disable-next-line no-param-reassign
                ch.imageUrl = child.imageUrl;
                // eslint-disable-next-line no-param-reassign
                ch.imgName = child.imgName;
              }
            });
          }
        }
      });

      const id = +this.$route.params.courseId === 0
        ? this.$route.params.programId
        : this.$route.params.courseId;

      if (this.editMode) {
        await ActivityService.updateCertificate({ id, config: obj });
      } else {
        await ActivityService.createCertificate({ id, config: obj }).then(() => {
          this.editMode = true;
        });
      }

      this.loadingSave = false;
    },
    saveImageToServer() {
      if (!this.formData) {
        return new Promise((resolve) => resolve(null));
      }
      const f = this.fileImage;
      const reader = new FileReader();

      reader.onload = (ev) => {
        const mime = ev.target.result.split(';')[0].split('data:')[1];
        this.formData.append('type', mime);
      };
      reader.readAsDataURL(f);

      return MaterialService.uploadCover(this.formData)
        .then(async ({ data: { imageHighres } }) => imageHighres)
        .catch(console.log);
    },

    handleTutorial(value) {
      if (value === 'skip') {
        this.tutorial.showTutorial = false;
        localStorage.setItem(certificateTutorial, JSON.stringify(this.tutorial));
        return;
      }
      if (Object.keys(this.tutorial).includes(value)) {
        this.tutorial[value] = false;
        localStorage.setItem(certificateTutorial, JSON.stringify(this.tutorial));
      }
    },

    removeCertificate() {
      this.loading = true;
      if (!this.editMode) {
        this.resetSertificate();
        this.loading = false;
      } else {
        const id = +this.$route.params.courseId === 0
          ? this.$route.params.programId
          : this.$route.params.courseId;

        ActivityService.deleteCertificate(id).then(() => {
          this.resetSertificate();
        }).catch(console.log)
          .finally(this.loading = false);
      }
    },
    reload() {
      window.location.reload();
    },
    resetSertificate() {
      this.canvasClass = '';
      this.hasData = false;
      this.imageUrl = null;
      this.imgName = null;
      this.konvaSettings = null;
      this.formData = null;
      this.fileImage = null;
      this.pdfToImageUrl = null;
      this.canvas = null;
      this.stage = null;
      this.layer = null;
      this.tr = null;
      this.selectedText = null;
      this.stageContainer = null;
      this.addedText = null;
      this.editMode = null;
      if (this.$refs.load?.value) {
        this.$refs.load.value = null;
      }
    },
  },

  beforeDestroy() {
    if (this.stageContainer) {
      this.stageContainer.removeEventListener('keydown', this.keyDownDelete);
    }
  },
};
</script>
